import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Our apologises, this page still needs to be translated. We'll leave you with the Dutch version in hopes that it still might help you.`}</p>
    <p>{`Dit Privacybeleid is van toepassing op alle persoonsgegevens die Groningen Griffins verwerkt van haar `}{`[`}{`leden, donateurs, deelnemers of andere geïnteresseerden`}{`]`}{`.`}</p>
    <h1>{`Privacy Verklaring`}</h1>
    <p>{`Indien je lid wordt van Groningen Griffins, een donatie doet of om een andere reden persoonsgegevens aan Groningen Griffins verstrekt, geef je uitdrukkelijk toestemming om je persoonsgegevens in lijn met dit Privacybeleid te verwerken.`}</p>
    <h2>{`Artikel 1. Uitleg persoonsgegevens en specifieke doelen voor de verwerking daarvan`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`In het kader van jouw lidmaatschap worden de volgende persoonsgegevens verwerkt:`}</p>
        <ul parentName="li">
          <li parentName="ul">{`voor- en achternaam, geboortedatum`}</li>
          <li parentName="ul">{`e-mailadres(sen)`}</li>
          <li parentName="ul">{`telefoonnummer(s)`}</li>
          <li parentName="ul">{`nood contact(en)`}</li>
          <li parentName="ul">{`gender`}</li>
          <li parentName="ul">{`rugnummer`}</li>
          <li parentName="ul">{`datum van lidmaatschap`}</li>
          <li parentName="ul">{`foto’s en video’s`}</li>
          <li parentName="ul">{`overige persoonskenmerken die benodigd kunnen zijn voor onze organisatie zoals bv social media accounts.`}</li>
        </ul>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Groningen Griffins verwerkt de in sub 1.1 genoemde persoonsgegevens voor de volgende doeleinden:`}</p>
        <ul parentName="li">
          <li parentName="ul">{`je naam, telefoonnummer en e-mailadres wordt gebruikt voor contact over het lidmaatschap en de eventuele opzegging daarvan, om betalingen, bv. van het lidmaatschapsgeld, toernooien, cursussen, trainingen, bestellingen, afgenomen diensten af te wikkelen’`}</li>
          <li parentName="ul">{`je naam, e-mailadres en telefoonnummer wordt gebruikt voor het versturen van uitnodigingen en informatie over diensten en activiteiten van Groningen Griffins,`}</li>
          <li parentName="ul">{`je nood contact wordt gebruik in de uitsluitende situatie dat er tijdens een Groningen Griffins activiteit iets gebeurd waarvoor er contact opgenomen moet worden met het noodcontact,`}</li>
          <li parentName="ul">{`je naam, gender en rugnummer worden gebruikt voor registratie van wedstrijden, friendlies, toernooien, speciale trainingen,`}</li>
          <li parentName="ul">{`je geboortedatum wordt gebruikt voor het controleren dat de speler boven de 16 is voor het lid zijn van het wedstrijdteam, en voor het controleren dat de speler boven de 18 is tijdens de sociale bijeenkomsten waarbij alcohol genuttigd kan/mag worden,`}</li>
          <li parentName="ul">{`je datum van lidmaatschap wordt gebruikt voor het controleren hoe lang de persoonsgegevens nog bewaard moeten worden,`}</li>
          <li parentName="ul">{`foto’s en video’s die gemaakt zijn op Groningen Griffins activiteiten, zoals maar niet uitsluitend tot, trainingen en wedstrijden, kunnen bewaard en gedeeld worden voor promotionele doeleinde,`}</li>
          <li parentName="ul">{`je naam en gender worden gedeeld met de trainer and coach, voor het opstellen van wedstrijdroosters,`}</li>
        </ul>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Je naam en telefoonnummer wordt tot uiterlijk een jaar na afloop van de overeenkomst gebruikt om je te vragen naar je ervaringen met Groningen Griffins en je te informeren over de ontwikkelingen van Groningen Griffins`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`E-mail berichtgeving (opt-out):`}</strong>{`
Groningen Griffins gebruikt je naam en e-mailadres om je haar e-mail nieuwsbrief met informatie over activiteiten, diensten en andere interessante informatie over het lidmaatschap van Groningen Griffins. Afmelding voor deze mailings is te allen tijde mogelijk via de afmeldlink onderaan de mailing.`}</p>
      </li>
    </ol>
    <h2>{`Artikel 2. Bewaartermijnen`}</h2>
    <p>{`Groningen Griffins verwerkt en bewaart je persoonsgegevens gedurende de duur van je lidmaatschap en tot maximaal twee jaar na afloop van dit lidmaatschap. Aansluitend worden de persoonsgegevens vernietigd. Fiscale gegevens, zoals maar niet uitsluitend tot naam, betalingen, datum van betaling en reden van betaling worden maximaal zeven jaar bewaard.`}</p>
    <h2>{`Artikel 3. Bewaarlocatie`}</h2>
    <p>{`Alle bovengenoemde gegevens worden opgeslagen op de Google Drive van Groningen Griffins. Alleen het bestuur heeft hiertoe toegang.`}</p>
    <h2>{`Artikel 4. Doorgifte aan derden`}</h2>
    <p>{`Voor wedstrijden en toernooien worden je naam, rugnummer, gender en andere informatie die nodig is voor het opgeven van spelers bij wedstrijden en toernooien, doorgegeven aan de desbetreffende organisatie.
Voor de wedstrijd jerseys worden je opgegeven shirt naam en rugnummer doorgegeven aan de desbetreffende leverancier.
Foto’s en video’s kunnen gedeeld worden via sociale media en andere promotionele middelen, bv. flyers en visitekaartjes.`}</p>
    <h2>{`Artikel 5. Inzagerecht, verwijdering en vragen klachten`}</h2>
    <ol>
      <li parentName="ol">{`Via de ledenadministratie van Groningen Griffins kan je een verzoek indienen om je persoonsgegevens in te zien, te ontvangen, te wijzigen of te verwijderen. Groningen Griffins zal je verzoek in behandeling nemen en je, binnen een maand na ontvangst hierover informeren`}</li>
      <li parentName="ol">{`Indien je bezwaar wilt maken tegen de (verdere) verwerking van je persoonsgegevens als bedoeld in artikel 1, kan je eveneens contact opnemen met het bestuur.`}</li>
      <li parentName="ol">{`Indien je klachten hebt over de wijze waarop Groningen Griffins je persoonsgegevens verwerkt of je verzoeken behandelt, kan je contact opnemen met het bestuur.`}</li>
      <li parentName="ol">{`Eventuele andere vragen of opmerkingen over dit Privacybeleid kunnen worden gericht aan het bestuur `}<a parentName="li" {...{
          "href": "mailto:info@groningengriffins.nl"
        }}>{`info@groningengriffins.nl`}</a></li>
    </ol>
    <h2>{`Artikel 6. Wijzigingen`}</h2>
    <p>{`Dit Privacybeleid kan worden gewijzigd. De wijzigingen worden via de mail bekend gemaakt, wanneer er een emailadres bekend is. Mocht dit niet het geval zijn, is het Privacybeleid wat op de website staat leidend.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      